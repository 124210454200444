import React from 'react';
import { Link } from 'react-router-dom'

export default function Home(){
 return (
      <div>
        {/* About Start */}
		
		 <div className="container-fluid hero-header bg-light py-5 mb-5">
			<div className="container py-5">
			  <div className="row g-5 align-items-center">
				<div className="col-lg-6">
				  <h1 className="display-4 mb-3 animated slideInDown">Kalpa Commercial, A Diversified Group Shaping A Stronger Better Tomorrow.</h1>
				  <p className="animated slideInDown">Welcome to Kalpa Commercial Limited, where style blends with comfort! Our team of passionate fashion experts believes that clothes should not only look great but also feel amazing. With years of experience in designing and producing high-quality garments, we are dedicated to delivering the best clothing solutions to our customers. At Kalpa, we pride ourselves on combining elegance with comfort to meet your fashion needs, ensuring that every piece makes you look and feel exceptional.</p>
					<Link to="/about" className="btn btn-primary py-3 px-4 animated slideInDown">
						Explore More
					</Link>				 
				</div>
				<div className="col-lg-6 animated fadeIn">
				  <img className="img-fluid animated pulse infinite" style={{"-webkit-animation-duration":"3s","animation-duration":"3s"}} src="assets/img/girl-selling-clothes-flea-market_1308-43448.avif" alt="hero-1.png" />
				</div>
			  </div>
			</div>
		  </div>
		  
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <img className="img-fluid" src="assets/img/Home-HowItWorks.jpg" alt="about.png" />
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="h-100">
                  <h1 className="display-6">Buy, Sell, Trade</h1>
                  <p className="text-primary fs-5 mb-4">How It Works</p>
                  <p>Kalpa Commercial Ltd. is a company that primarily engages in garment and textile trading. Based on typical operations of such companies, Kalpa Commercial Ltd. might be involved in activities such as sourcing, manufacturing, exporting, and distributing clothing and textiles, which could include fabrics, ready-made garments, and related fashion accessories.</p>
                  <p>In the garment trade, companies like Kalpa Commercial Ltd. often work with manufacturers and suppliers to manage the supply chain, ensuring that raw materials are converted into finished clothing products for distribution. These companies might also focus on international trade, working with brands, retailers, and wholesalers globally.</p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
       
        {/* Features Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{"max-width":"500px"}}>
              <h1 className="display-6">Why Us!</h1>
              <p className="text-primary fs-5 mb-5">At Kalpa Commercial Ltd, we stand out in the garment trade industry by offering more than just high-quality apparel. Here’s why our clients continue to choose us:</p>
            </div>
            <div className="row g-5">
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="d-flex align-items-start">
                  <img className="img-fluid flex-shrink-0" src="assets/img/quality.jpg" alt="icon-7.png" style={{"width":"65px"}} />
                  <div className="ps-4">
                    <h5 className="mb-3">Premium Quality Assurance</h5>
                    <span>Our commitment to quality is unparalleled. From sourcing the finest materials to ensuring meticulous craftsmanship, every garment we produce undergoes rigorous quality checks to meet international standards.

</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="d-flex align-items-start">
                  <img className="img-fluid flex-shrink-0" style={{"width":"65px"}} src="assets/img/customized-solutions-icon-hands-innovation-graphic-collaborative-problem-solving-symbol-tailored-teamwork-editable-stroke-297101740.webp" alt="icon-6.png" />
                  <div className="ps-4">
                    <h5 className="mb-3">Tailored Solutions</h5>
                    <span>As a company committed to sustainability, we actively adopt eco-friendly materials and ethical manufacturing processes. By reducing waste and energy use, we ensure that our operations have a minimal environmental impact.

</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="d-flex align-items-start">
                  <img className="img-fluid flex-shrink-0" src="assets/img/ethical-responsibility-vector-illustration-style_717774-27095.avif" alt="icon-5.png" style={{"width":"65px"}} />
                  <div className="ps-4">
                    <h5 className="mb-3">Sustainable and Ethical Practices</h5>
                    <span>Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="d-flex align-items-start">
                  <img className="img-fluid flex-shrink-0" src="assets/img/download.png" alt="icon-4.png" style={{"width":"65px"}} />
                  <div className="ps-4">
                    <h5 className="mb-3">Global Reach, Local Expertise</h5>
                    <span>With extensive experience in global markets, we have the logistics and infrastructure in place to deliver to clients around the world. We combine local expertise with a global network to ensure timely, cost-effective solutions for your garment needs.

</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="d-flex align-items-start">
                  <img className="img-fluid flex-shrink-0" src="assets/img/competitive-pricing-rubber-grunge-stamp-seal-vector.jpg" alt="icon-3.png" style={{"width":"65px"}} />
                  <div className="ps-4">
                    <h5 className="mb-3">Competitive Pricing Without Compromise</h5>
                    <span>Our efficient supply chain and strategic partnerships allow us to offer highly competitive pricing without sacrificing the quality of our products. We focus on delivering value to our clients at every stage of the production process.

</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="d-flex align-items-start">
                  <img className="img-fluid flex-shrink-0" src="assets/img/financial-statement-icon-in-comic-style-result-cartoon-illustration-on-white-isolated-background-report-splash-effect-business-concept-vector.jpg" alt="icon-8.png" style={{"width":"65px"}} />
                  <div className="ps-4">
                    <h5 className="mb-3">Innovative Designs and Trend Insights</h5>
                    <span>Our team stays ahead of industry trends, ensuring that our designs are not only fashionable but also relevant to current market demands. We offer trend analysis and consultation services to keep your brand fresh and in demand.

</span>
                  </div>
                </div>
              </div>
			   <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="d-flex align-items-start">
                  <img className="img-fluid flex-shrink-0" src="assets/img/10074958.png" alt="icon-8.png" style={{"width":"65px"}} />
                  <div className="ps-4">
                    <h5 className="mb-3">Strong Customer Relationships</h5>
                    <span>We believe in building long-term partnerships with our clients. From the initial consultation to post-delivery support, we prioritize open communication, reliability, and customer satisfaction at every touchpoint.

</span>
                  </div>
                </div>
              </div>
			   <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="d-flex align-items-start">
                  <img className="img-fluid flex-shrink-0" src="assets/img/efficiency-productivity-icon-vector_116137-5296.avif" alt="icon-8.png" style={{"width":"65px"}} />
                  <div className="ps-4">
                    <h5 className="mb-3">Efficient Production and Timely Delivery</h5>
                    <span>Our streamlined manufacturing processes and well-managed logistics ensure that even the most complex orders are delivered on time. We maintain a sharp focus on efficiency and precision to meet your deadlines, regardless of order size.

</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Features End */}
        {/* Service Start */}
        <div className="container-xxl bg-light py-5 my-5">
          <div className="container py-5">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{"max-width":"500px"}}>
              <h1 className="display-6">Services</h1>
              <p className="text-primary fs-5 mb-5">At Kalpa Commercial Ltd, we provide a comprehensive range of garment-related services designed to meet the diverse needs of our clients. Our offerings ensure a seamless experience, from concept to delivery.
</p>
            </div>
            <div className="row g-4">
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-item bg-white p-5">
				                  <img className="img-fluid mb-4" src="assets/img/images (1).png" alt="icon-8.png" style={{"width":"65px"}} />

                  <h5 className="mb-3">Custom Garment Manufacturing</h5>
                  <p>We specialize in producing custom-designed garments that meet the unique specifications of our clients. Whether it's specific fabrics, styles, or patterns, we ensure precision and quality in every piece.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-item bg-white p-5">
								                  <img className="img-fluid mb-4" src="assets/img/images (2).png" alt="icon-8.png" style={{"width":"65px"}} />

                  <h5 className="mb-3">Private Labeling</h5>
                  <p>We offer private labeling services, allowing our clients to create their own branded clothing lines. From product design to final packaging, we handle everything while ensuring your brand vision is represented.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-item bg-white p-5">
												                  <img className="img-fluid mb-4" src="assets/img/images (3).png" alt="icon-8.png" style={{"width":"65px"}} />

                  <h5 className="mb-3">Sourcing of Raw Materials</h5>
                  <p>Our extensive network of suppliers allows us to source high-quality raw materials, such as fabrics and accessories, at competitive prices. We prioritize eco-friendly and sustainable materials wherever possible.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="service-item bg-white p-5">
																                  <img className="img-fluid mb-4" src="assets/img/quality.jpg" alt="icon-8.png" style={{"width":"65px"}} />

                  <h5 className="mb-3">Quality Assurance and Control</h5>
                  <p>We follow a stringent quality control process at every stage of production to ensure that each garment meets international standards. Our team conducts thorough inspections to maintain consistency and reliability. </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div className="service-item bg-white p-5">
																				                  <img className="img-fluid mb-4" src="assets/img/970400-200.png" alt="icon-8.png" style={{"width":"65px"}} />

                  <h5 className="mb-3">Design and Trend Consultation</h5>
                  <p>Our in-house design team stays updated on the latest fashion trends and can offer design consultancy services to help your brand stay ahead of the curve. We work closely with clients to create innovative and market-ready designs. </p>
               

			   </div>
              </div>
              <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div className="service-item bg-white p-5">
																								                  <img className="img-fluid mb-4" src="assets/img/3347791.png" alt="icon-8.png" style={{"width":"65px"}} />

                  <h5 className="mb-3">Global Distribution and Export</h5>
                  <p>With robust logistics and distribution channels, we offer seamless export services, delivering garments to clients around the world. We ensure timely, safe, and cost-effective shipment of products to any destination.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service End */}
      
	  
       
        {/* FAQs Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{"max-width":"500px"}}>
              <h1 className="display-6">FAQs</h1>
              <p className="text-primary fs-5 mb-5">Frequently Asked Questions</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item wow fadeInUp" data-wow-delay="0.1s">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                       It's my first time selling. What should I expect?
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        Thank you for choosing to sell at Crossroads!

				Visit any Crossroads (excluding our Stockton outlet) to sell. No appointment is necessary. Find your nearest store, and make sure to bring a government-issued ID or, if you’re under 18, an adult with a government-issued ID.

				You’ll be greeted and instructed to join the waitlist when you arrive (Pro tip: download the Crossroads Waitlist App for a real-time look at line length and to join the waitlist before getting to the store).

				When it’s your turn, a Buyer will go through your items. You can shop the store or stay with your Buyer during this time. Feel free to ask your Buyer why they chose or pass on certain items, how items are priced, and any other questions you have.

				The Buyer will tell you how much cash or trade you’ve earned. Visit the register to get your payout, or return another day to claim it. Your earned balance is automatically applied to your Crossroads digital account, available in any of our stores by providing your ID and phone number.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeInUp" data-wow-delay="0.2s">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How do your Buyers choose what items to buy?
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
							Buyers consider many things when deciding what to buy, like style, brand, condition and fabric. Most importantly, they have to consider what customers are buying at their store and current stock levels. Our goal is to buy as much as we can from every person who comes in to sell based on what we know from experience our customers will buy.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeInUp" data-wow-delay="0.3s">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        I brought in an item that was on the selling guide. Why did your buyer pass on it?
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
						Our Selling Guide is a great place to find what trends and styles we’re excited about company-wide, but a Buyer’s primary focus is their particular store. They consider what their customers are buying and what items they currently have in their store.

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeInUp" data-wow-delay="0.4s">
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                        Is there anything you don't buy?
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
					We buy on-trend men’s and women’s clothing, shoes and accessories. We do not buy children’s items, formal wear, traditional business wear, intimates, items with extreme wear or counterfeit items.

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeInUp" data-wow-delay="0.5s">
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
						What is consignment, and how is it different than regular selling?
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                           We offer consignment on luxury, collectible or designer goods. When you choose to consign your items, you’ll get paid when the items sell instead of right away. Payouts for consignment items range from 50-70%, depending on the selling price. If you have an item you’d like to consign, talk to your Buyer.

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item wow fadeInUp" data-wow-delay="0.6s">
                    <h2 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
						I don’t have time to sell in person at Crossroads. Is there any other way to sell?
                      </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
						Yes! Sell to Crossroads without making a trip to the store with our Sell by Mail program. Send us your clothing, shoes and accessories in a pre-paid UPS bag. Receive cash or trade for the items we buy in! Learn more here.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FAQs Start */}
      </div>
    );
}