import React from 'react';
import { Link } from 'react-router-dom'

export default function Investor(){
  return (
     <div>
     {/* Header Start */}
	  <div className="container-fluid hero-header bg-light py5 mb-5"  style={{"padding-top":"1rem !important","padding-bottom":"1rem !important"}}>
		<div className="container py-5">
		  <div className="row g-5 align-items-center">
			<div className="col-lg-6">
			  <h1 className="display-4 mb3 animated slideInDown">Investor Relation</h1>
			  <nav aria-label="breadcrumb animated slideInDown">
				<ol className="breadcrumb mb-0">
				  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
				  <li className="breadcrumb-item"><Link to="/">Pages</Link></li>
				  <li className="breadcrumb-item active" aria-current="page">Investor Relation</li>
				</ol>
			  </nav>
			</div>
			
			
		  </div>
		</div>
	  </div>
	  {/* Header End */}
	  {/* About Start */}
	  <div className="container-xxl py-5">
		<div className="container">
		  <div className="row g-5 align-items-center">
			<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
			  <img className="img-fluid" src="assets/img/vission.avif" alt="vission.avif" />
			</div>
			<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
			  <div className="h-100">
				<h1 className="display-6">Our Vission</h1>
				<p className="text-primary fs-5 mb-4">Sustainable Global Leadership in Quality Garments and Service</p>
				<p>To be a globally recognized leader in the garment trade, providing high-quality, innovative, and sustainable apparel solutions that enhance the lives of people worldwide. We strive to set new industry benchmarks by embracing ethical practices, fostering creativity, and staying ahead of market trends, while making a positive impact on communities and the environment.</p>
			  </div>
			  
			</div>
			<div className="vission-text">
							<h1 className="display-6">Our Mission</h1>
    
			<p>At Kalpa Commercial Ltd, our mission is to deliver superior garment products that meet the evolving needs of our clients while maintaining a focus on quality, affordability, and sustainability. We aim to:</p>
			<p><span className="heighlight-text" style={{"font-weight":"700"}}>Exceed customer expectations:</span>  through innovation, design, and attention to detail.</p>
			<p><span className="heighlight-text" style={{"font-weight":"700"}}>Promote sustainable practices:</span>  by minimizing our environmental footprint and supporting ethical production methods.</p>
			<p><span className="heighlight-text" style={{"font-weight":"700"}}>Build long-term partnerships: </span>by offering exceptional service, tailored solutions, and reliable global delivery.</p>
			<p><span className="heighlight-text" style={{"font-weight":"700"}}>Empower our workforce:</span>by fostering a collaborative, inclusive, and safe working environment.</p>
			<p><span className="heighlight-text" style={{"font-weight":"700"}}>Contribute to the industry’s growth:</span>by staying at the forefront of fashion trends, technology, and best practices.</p>
			</div>
		
		  </div>
		</div>
	  </div>
	  {/* About End */}
	</div>
    );
}