import React from 'react';
import { Link } from 'react-router-dom'

export default function Footer(){
  return (
      <div className="container-fluid bg-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h5 className="mb-4">Get In Touch</h5>
              <p><i className="fa fa-map-marker-alt me-3" />First Floor, 984 Pocket C, IFC Ghazipur Paper Market, East Delhi, Delhi, India, 110096</p>

              <p><i className="fa fa-envelope me-3" />info@kalpacommercialltd.com</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="mb-4">Quick Links</h5>
			  <Link to="#" className="btn btn-link">News</Link>
               <Link to="#" className="btn btn-link">Career</Link>
                {/*<Link to="\#" target='_blank' className="btn btn-link">Employee</Link> */}
               <Link to="#" className="btn btn-link">Notice</Link>
               <Link to="/contact" className="btn btn-link">Contact US</Link>
				
            
			
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="mb-4">Investor Relation</h5>
               {/* <Link to="/financial-result" className="btn btn-link">Financial Results</Link>
                <Link to="/general-meeting" className="btn btn-link">General Meetings & Postal Ballot</Link> */}
                <Link to="#" className="btn btn-link">Annual Return</Link>
                <Link to="#" className="btn btn-link">Annual Reports</Link>
                <Link to="#" className="btn btn-link">Codes & Policies</Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="mb-4">Follow Us</h5>
              <div className="d-flex">
			  
			    <Link to="#" className="btn btn-square rounded-circle me-1"><i className="fab fa-twitter" /></Link>
                <Link to="#" className="btn btn-square rounded-circle me-1"><i className="fab fa-facebook-f" /></Link>
                <Link to="#" className="btn btn-square rounded-circle me-1"><i className="fab fa-youtube" /></Link>
                <Link to="#" className="btn btn-square rounded-circle me-1"><i className="fab fa-linkedin-in" /></Link>
				
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                © <Link to="#">Kalpa Commercial LTD</Link>, All Right Reserved.
              </div>
             
			 
            </div>
          </div>
        </div>
      </div>
    );
}