import React from 'react';
import { Link } from 'react-router-dom'

export default function Annualsecretaria(){
  return (
     <div>
     {/* Header Start */}
	  <div className="container-fluid hero-header bg-light py5 mb-5"  style={{"padding-top":"1rem !important","padding-bottom":"1rem !important"}}>
		<div className="container py-5">
		  <div className="row g-5 align-items-center">
			<div className="col-lg-12">
			  <h1 className="display-4 mb3 animated slideInDown">Annual Secretarial Compliance Report	</h1>
			  <nav aria-label="breadcrumb animated slideInDown">
				<ol className="breadcrumb mb-0">
				  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
				  <li className="breadcrumb-item"><Link to="/">Investor Relation</Link></li>
				  <li className="breadcrumb-item active" aria-current="page">Annual Secretarial Compliance Report	</li>
				</ol>
			  </nav>
			</div>
			
			
		  </div>
		</div>
	  </div>
	  {/* Header End */}
	  {/* About Start */}
	  <div className="container-xxl py5">
		<div className="container">
		  <div className="row g-5 align-items-center">
			<div className="table-responsive table_heading">
				  <table className="table table-bordered table-striped">
					<thead>
					  <tr>
						{/*<th width="20" class="text-center">No.</th>*/}
						<th className="text-left">Annual Secretarial Compliance Report	</th>
						<th>Download Link</th>
					  </tr>
					</thead>
					<tbody>
					  <tr>
						<td></td>
						<td width={280} className="text-center">
						 <Link to="#" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					 
					</tbody>
				  </table>
				</div>

			
		  </div>
		</div>
	  </div>
	  {/* About End */}
	</div>
    );
}