import React from 'react';
import { Link } from 'react-router-dom'

export default function Policies(){
  return (
     <div>
     {/* Header Start */}
	  <div className="container-fluid hero-header bg-light py5 mb-5"  style={{"padding-top":"1rem !important","padding-bottom":"1rem !important"}}>
		<div className="container py-5">
		  <div className="row g-5 align-items-center">
			<div className="col-lg-12">
			  <h1 className="display-4 mb3 animated slideInDown">Codes & Policies</h1>
			  <nav aria-label="breadcrumb animated slideInDown">
				<ol className="breadcrumb mb-0">
				  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
				  <li className="breadcrumb-item"><Link to="/">Investor Relation</Link></li>
				  <li className="breadcrumb-item active" aria-current="page">Codes & Policies</li>
				</ol>
			  </nav>
			</div>
			
			
		  </div>
		</div>
	  </div>
	  {/* Header End */}
	  {/* About Start */}
	  <div className="container-xxl py5">
		<div className="container">
		  <div className="row g-5 align-items-center">
			<div className="table-responsive table_heading">
				  <table className="table table-bordered table-striped">
					<thead>
					  <tr>
						{/*<th width="20" class="text-center">No.</th>*/}
						<th className="text-left">Codes & Policies</th>
						<th>Download Link</th>
					  </tr>
					</thead>
					<tbody>
					  <tr>
						<td>Code of Conduct for Board of Directors and Senior Management</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Code-of-Conduct-for-Board-of-Directors-and-Senior-Management.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					   <tr>
						<td>Code of Independent Directors and Terms and Conditions for their Appointment</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Code-of-Independent-Directors-and-Terms-and-Conditions-for-their-Appointment.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					  
					   <tr>
						<td>Insider Trading Policy</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Insider-Trading-Policy.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					   <tr>
						<td>Nomination & Remuneration Policy</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Nomination-&-Remuneration-Policy.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					   <tr>
						<td>Policy on determining of Materiality of events and info.</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Policy-on-determining-of-Materiality-of-events-and-info.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					   <tr>
						<td>Policy on Materiality of RPTs</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Policy-on-Materiality-of-RPTs.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>  
					   <tr>
						<td>Policy on Prevention of Sexual Harassment at Workplace</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Policy-on-Prevention-of-Sexual-Harassment-at-Workplace.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  
					  <tr>
						<td>Preservation & Archival of Documents-Policy</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Preservation-&-Archival-of-Documents-Policy.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>

					  <tr>
						<td>Risk Management Policy</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Risk-Management-Policy.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					  <tr>
						<td>Whistle Blower Policy</td>
						<td width={280} className="text-center">
						 <Link to="/codes-policies/Whistle-Blower-Policy.pdf" target="_blank">
							Download
						  </Link>{" "}
						</td>
					  </tr>
					 
					</tbody>
				  </table>
				</div>

			
		  </div>
		</div>
	  </div>
	  {/* About End */}
	</div>
    );
}