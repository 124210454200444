import React from 'react';
import { Link } from 'react-router-dom'
export default function Contact(){
  return (
      <div>
        {/* Header Start */}
	  <div className="container-fluid hero-header bg-light py5 mb-5"  style={{"padding-top":"1rem !important","padding-bottom":"1rem !important"}}>
          <div className="container py-5">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6">
                <h1 className="display-4 mb-3 animated slideInDown">Contact Us</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                  </ol>
                </nav>
              </div>
             
            </div>
          </div>
        </div>
        {/* Header End */}
        {/* Contact Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
              <div className="col-lg-6">
                <h1 className="display-6">Contact Us</h1>
                <p className="text-primary fs-5 mb-0">If You Have Any Query, Please Contact Us</p>
              </div>
             
            </div>
            <div className="row g-5">
              <div className="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <p className="mb-2">Our office:</p>
                <h4>First Floor, 984 Pocket C, IFC Ghazipur Paper Market, East Delhi, Delhi, India, 110096</h4>
              
                <hr className="w-100" />
                <p className="mb-2">Mail us:</p>
                <h4>info@kalpacommercialltd.com</h4>
                <hr className="w-100" />
                <p className="mb-2">Follow us:</p>
                <div className="d-flex pt-2">
                  <a className="btn btn-square btn-primary rounded-circle me-2" href><i className="fab fa-twitter" /></a>
                  <a className="btn btn-square btn-primary rounded-circle me-2" href><i className="fab fa-facebook-f" /></a>
                  <a className="btn btn-square btn-primary rounded-circle me-2" href><i className="fab fa-youtube" /></a>
                  <a className="btn btn-square btn-primary rounded-circle me-2" href><i className="fab fa-linkedin-in" /></a>
                </div>
              </div>
              <div className="col-lg-7 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
			     <p class="mb-4">For genereal enquires you can touch with our front desk supporting team at infokalpa2@gmail.com</p>
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="name" placeholder="Your Name" />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="email" className="form-control" id="email" placeholder="Your Email" />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="subject" placeholder="Subject" />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea className="form-control" placeholder="Leave a message here" id="message" style={{"height":"100px"}} defaultValue={""} />
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary py-3 px-4" type="submit">Send Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Contact End */}
        {/* Google Map Start */}
        <div className="container-xxl py-5 px-0 wow fadeInUp" data-wow-delay="0.1s">
		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.208397189628!2d77.33164477945616!3d28.623515621776846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5289ec505fd%3A0x3ea6c0f29bfe2ff3!2s984%2C%20Integrated%20Freight%20Complex%2C%20Pocket%20C%2C%20IFC%2C%20Ghazipur%2C%20Delhi%2C%20110096!5e0!3m2!1sen!2sin!4v1727671762982!5m2!1sen!2sin" width={1260} height={450} style={{"border":"0"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />        </div>
        {/* Google Map End */}
      </div>
    );
}